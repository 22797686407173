#customSlideShow{
    /* Slideshow container */
    .slideshow-container {
        max-width: 1000px;
        position: relative;
        margin: auto;
    }
    
    /* Next & previous buttons */
    .prev, .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        padding: 16px;
        margin-top: -22px;
        color: #92D8F0;
        font-weight: bold;
        font-size: 40px;
        transition: 0.6s ease;
        border-radius: 3px 0 0 3px;
        user-select: none;
        opacity: 0.8;
    }

    .dotWrapper, .labelSlide{
        margin-top: 40px;
    }

    .prev{
        left: -20px;
    }
    
    /* Position the "next button" to the right */
    .next {
        right: -20px;
        border-radius: 0 3px 3px 0;
    }
    
    /* On hover, add a black background color with a little bit see-through */
    .prev:hover, .next:hover {
        // background-color: rgba(0,0,0,0.8);
        // color: #23307F;
        opacity: 1;
    }

    /* Caption text */
    .text {
        color: #f2f2f2;
        font-size: 15px;
        padding: 8px 12px;
        position: absolute;
        bottom: 8px;
        width: 100%;
        text-align: center;
    }

    /* Number text (1/3 etc) */
    .numbertext {
        color: #23307F;
        font-size: 12px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
    }

    /* The dots/bullets/indicators */
    .dot {
        cursor: pointer;
        height: 10px;
        width: 10px;
        margin: 0 2px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.6s ease;
    }

    .active, .dot:hover {
        background-color: #6075BF;
    }

    /* Fading animation */
    .fade {
        -webkit-animation-name: fade;
        -webkit-animation-duration: 1.5s;
        animation-name: fade;
        animation-duration: 1.5s;

        // img{
        //     // border-radius: 5px;
        // }
    }

    @-webkit-keyframes fade {
        from {opacity: .4} 
        to {opacity: 1}
    }

    @keyframes fade {
        from {opacity: .4} 
        to {opacity: 1}
    }

    /* On smaller screens, decrease text size */
    @media only screen and (max-width: 300px) {
        .prev, .next,.text {font-size: 11px}
    }
}