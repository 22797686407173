#selc5_page{
    .ui.button{
        box-shadow: 2px 2px 10px rgba(13, 71, 161, 0.25);
        border-radius: 100px;
        opacity: 0.9;
        transition: 0.5s;
    }
    
    .ui.primary.button:not(.ui.disabled.button){
        background: linear-gradient(90deg, #1976D2 0%, #00BCD4 100%);
    }

    h1,h2,h3,h4,h5,h6,p,b{
        font-family: 'Lato' !important;
        color: #272343;
    }
    min-height: 100vh;
    margin-top: 115px;
    
    h1{
        font-size: xxx-large !important;
    }

    h1.primary{
        color: #23307F;
    }

    .descSelc{
        line-height: 178.18%;
        font-size: 18px;
    }

    .banner_5_d{
        // max-width: 360px;
        position: absolute;
        right: 0;
        z-index: 1;
        top: -66px;
    }

    .quickButtons{
        position: fixed;
        left: 20px;
        top: 150px;
        z-index: 101;
        // cursor: pointer;

        .ui.menu{
            background-color: #3A0BA5;
            border-radius: 50px;
            padding-top: 30px;
            padding-bottom: 30px;
            width: 100%;
            transition: height 0.5s;
            -webkit-transition: height 0.5s;
            text-align: center;
            overflow: hidden;
            transition: 0.5s;

            .item::before{
                background: none !important;
            }

            .item{
                margin-bottom: 10px;
                color: #FFF !important;
            }

            i{
                font-size: 18px;
                text-align: center;
            }

            img{
                width: 20px;
            }

            span{
                display: none;
            }
        }

        .ui.menu:hover{
            width: 15vw;
            .item{
                margin-bottom: 20px;
            }
            i,img{
                float: left;
                text-align: center;
            }

            span{
                display: inline;
            }
        }
    }

    .selc_sponsor_images img{
        width: 20%;
        max-width: 200px;
        height: 150px;
/*        max-height: 150px;*/
        object-fit: contain;
    }

    #m_join{
        display: none;
    }

    h1, h2{
        // font-family: 'Poppins' !important;
        font-weight: bolder !important;
    }

    #selc_banner{
        padding-bottom: 40px;
    }

    #selc_banner_2{
        .banner{
            background-color: #FED104;
            padding-bottom: 40px;
            padding-top: 40px;

            .banner_grid{
                h1{
                    color: #fff !important;
                }
            }
        }
    }

    #selc_info{

        .ui.list{
            a{
                text-decoration: underline;
                color: #000;
            }
        }

        h2{
            color: #3A0BA5 !important;
        }
        .info{
            padding-bottom: 100px;
            padding-top: 40px;
            // background-color: #D1FCFF;
        }
    }

    #selc_join{
        background-color: #D1FCFF;
        padding-bottom: 70px;
        padding-top: 70px;

        h2{
            color: #3A0BA5 !important;
        }

        .comp_header{
            color: #7C768A !important;
        }

        .selc_btn_group_div{
            margin-top: 70px;
            margin-bottom: 70px;
        }

        .ui.button{
            padding: 0.785714em 4.5em .78571429em !important;
        }

        #selc_video_winner{
            margin-top: 70px;
        }
    }

    #selc_info_m{
        padding-top: 40px;
        img{
            display: none;
        }
    }

    #selc_stages{
        padding-top: 40px;
        padding-bottom: 70px;
        #stages{
            padding-top: 70px;
            padding-bottom: 70px;
            
            .stage_grid{
                h2{
                    font-size: 16px;
                    margin: 0;
                }
                
                .row{
                    padding-bottom: 0;
                    .column:first-child{
                        padding-right: 0;

                        .ui.grid{
                            .column:last-child{
                                display: flex;
                                align-items: center;
                            }
                        }
                    }

                    .column:last-child > .segment > .ui.grid > .column{
                        display: flex;
                        align-items: center;
                    }

                    .segment{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
                .row:not(.active) > .column > .segment{
                    h2, i{
                        color: #7C768A !important;
                        font-weight: normal !important;
                    }
                    background-color: #E3EEEF;
                }

                .row.active{
                    h2{
                        color: #fff !important;
                    }
                    .column > .segment{
                        background-color: #8E279B;
                        color: #fff;
                    }

                    .stage_status > span{
                        margin: 1px;
                    }
                }
            }
        }
    }

    #selc_rules{
        padding-top: 70px;
        padding-bottom: 70px;
        background-color: #D1FCFF;
    }

    #selc_gallery{
        padding-bottom: 70px;
        padding-top: 70px;
    }

    #selc_faq{
        padding-bottom: 70px;
        padding-top: 70px;

        #faq_content{
            .title{
                background-color: #D1FCFF;
            }
            .title > h1{
                color: #7C768A;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .content{
                padding-top: 20px;
            }

            h3{
                color: #7C768A;

                .sub.header{
                    margin-top: 5px;
                    line-height: 178.18%;
                    color: #797979;
                    b{
                        color: #585858;
                    }
                }
            }
        }
    }
}

.selc5_list .item{
    line-height: 178.18% !important;
}

.ui.ordered.list .item::before{
    content: counter(ordered) ". " !important;
}

.telegram_username{
    color: #848484;
    font-style: italic;
    font-size: 80% !important;
}
.username{
    margin-right: 10px;
}

#selec5_rule_modal{
    .content{
        padding: 50px !important;

        h1{
            color: #3A0BA5;
            font-family: 'Poppins' !important;
            font-weight: bolder !important;
        }

        .rule_grid{
            margin-top: 50px;
            .row{
                padding-top: 0 !important;
                padding-bottom: 0.5rem !important;
                .column{
                    padding-left: 0rem !important;
                    padding-right: 0.5rem !important;

                    .segment{
                        background-color: #E3EEEF;
                        color: #7C768A;
                        border: 0;
                    }
                }

                .column:not(:first-child){
                    .segment{
                        text-align: center;
                    }
                }
            }
            
            // purple color
            .row:first-child{
                .column > .segment{
                    background-color: #8E279B !important;
                    color: #fff;
                }
            }

            // yellow color
            .row.yellow-colored:first-child, .row.yellow-colored:nth-child(2){
                .column > .segment{
                    background-color: #FBC20D !important;
                    color: #fff;
                }
            }

            // grey color
            .row.grey-colored:first-child, .row.grey-colored:nth-child(2){
                .column > .segment{
                    background-color: #9EA3A3 !important;
                    color: #fff;
                }
            }

            // orange color
            .row.orange-colored:first-child, .row.orange-colored:nth-child(2){
                .column > .segment{
                    background-color: #FABE73 !important;
                    color: #8E279B;
                }
            }
        }

        .rule_grid.double{
            // purple color
            .row:first-child, .row:nth-child(2){
                .column > .segment{
                    background-color: #8E279B !important;
                    color: #fff;
                }
            }

            // yellow color
            .row.yellow-colored:first-child, .row.yellow-colored:nth-child(2){
                .column > .segment{
                    background-color: #FBC20D !important;
                    color: #fff;
                }
            }

            // grey color
            .row.grey-colored:first-child, .row.grey-colored:nth-child(2){
                .column > .segment{
                    background-color: #9EA3A3 !important;
                    color: #fff;
                }
            }

            // orange color
            .row.orange-colored:first-child, .row.orange-colored:nth-child(2){
                .column > .segment{
                    background-color: #FABE73 !important;
                    color: #8E279B;
                }
            }
        }


    }

    .angle.icon{
        color: #92D8F0;
        position: absolute;
        top: 44px;
        opacity: 0.8;
    }

    .angle.icon:hover{
        opacity: 1;
    }

    .angle.left.icon{
        left: 0;
    }

    .angle.right.icon{
        right: 0;
    }
}

#selc5_leaderboard{
    min-height: 100vh;
    margin-top: 60px ;

    section{
        margin-bottom: 50px;
    }

    #searchInput{
        border-radius: 0 50px 50px 0 !important;
    }

    #dateWrapper{
        .field{
            width: 100%;
        }
    }

    #banner{
        background-color: #eee;
        background-size: cover;
        height: 70vw;
        max-height: 550px;
        background-position: bottom center;
        padding-top: 15px;
    }

    #stage{
        #stages{
            .stage_grid{
                h2{
                    font-size: 16px;
                    margin: 0;
                }
                
                .row{
                    padding-bottom: 0;
                    .column:first-child{
                        padding-right: 0;

                        .ui.grid{
                            .column:last-child{
                                display: flex;
                                align-items: center;
                            }
                        }
                    }

                    .column:last-child > .segment > .ui.grid > .column{
                        display: flex;
                        align-items: center;
                    }

                    .segment{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
                .row:not(.active) > .column > .segment{
                    h2, i{
                        color: #7C768A !important;
                        font-weight: normal !important;
                    }
                    background-color: #E3EEEF;
                }

                .row.active{
                    h2{
                        color: #fff !important;
                    }
                    .column > .segment{
                        background-color: #8E279B;
                        color: #fff;
                    }

                    .stage_status > span{
                        margin: 1px;
                    }
                }
            }
        }
    }

    #leaderboard{
        .lbTable{
            max-height: 615px;
            overflow: overlay;
            
            .ui.button{
                border-radius: 5px !important;
                background-color: #FFFFFF;
                color: #8E279B;
            }

            .no-data{
                justify-content: center;
                .column{
                    background-color: #fff !important;
                }
            }

            .row.achieved:not(:first-child):not(.details){
                .column, .inside .row{
                    background-color: #FED104;
                }
            }

            .row:not(:first-child){
                .two.column > .ui.grid > .column{
                    border-radius: 0 5px 5px 0;
                }
            }

            .row:not(:first-child):not(.details){
                .column:nth-child(2), .column:nth-child(3), .column:nth-child(4), .column:nth-child(5){
                    margin-left: 5px;
                }

                .column{
                    background-color: #D1FCFF;
                    font-size: large;
                }

                .inside .row{
                    border-radius: 5px 0 0 5px;
                    background-color: #D1FCFF;
                    [class*="three wide"].column{
                        width: 19.75%!important;
                    }
                }

                .inside{
                    .column{
                        margin-left: 0 !important;
                    }
                }

                margin-bottom: 5px;
            }

            .row.details{
                .column:nth-child(2), .column:nth-child(3), .column:nth-child(4), .column:nth-child(5){
                    margin-left: 5px;
                }

                .column:not(:nth-child(4)):not(:nth-child(5)):not(:nth-child(6)){
                    background-color: #E3EEEF;
                    font-size: large;
                }

                .red.column{
                    background-color: #fff !important;
                }

                .inside .row{
                    background-color: #E3EEEF;
                    [class*="three wide"].column{
                        width: 19.75%!important;
                    }
                }

                .inside{
                    .column{
                        margin-left: 0 !important;
                    }
                }

                margin-bottom: 5px;
            }

            .row.details.first{
                margin-top: 15px;
            }

            .row.details.last{
                margin-bottom: 20px;
            }

            .row:first-child{
                b{
                    font-size: large;
                }

                b.center{
                    text-align: center;
                }

                .column:nth-child(3), .column:nth-child(4), .column:nth-child(5), .column:nth-child(6){
                    margin-left: 5px;
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }

        .lbTableMobile{
            display: none;
        }
    }

    .ui.primary.button:not(.ui.disabled.button){
        background: linear-gradient(90deg, #1976D2 0%, #00BCD4 100%);
    }

    .ui.button{
        box-shadow: 2px 2px 10px rgba(13, 71, 161, 0.25);
        border-radius: 100px;
        opacity: 0.9;
        transition: 0.5s;
    }

    .ui.button:hover{
        opacity: 1;
    }
}

@media only screen and (min-width: 768px) and (max-width: 912px) {
    .banner_5_d{
        max-width: 360px !important;
        top: 25px !important;
    }
}

@media only screen and (min-width:320px) and (max-width:600px){
    #selc5_page{
        .banner_5_d{
            display: none;
        }

        #selc_info_m{
            img{
                display: block;
                margin-top: 30px;
            }
            .segment{
                padding: 0;
            }
        }

        .headerSelc, .ui.header.primary{
            font-size: xx-large !important;
        }

        .banner_grid .column{
            width: 100%;
            text-align: center;
        }

        .descSelc{
            font-size: 14px;
        }

        #selc_stages{
            padding-bottom: 0;
        }

        #selc_info{
            .info{
                padding-bottom: 70px;
                padding-top: 70px;
            }
        }

        #selc_banner_2 .banner{
            padding-bottom: 0;
            padding-top: 0;
        }

        #selc_gallery{
            padding-bottom: 0;
            .ui.container{
                margin: 0 !important;
            }
        }

        #selc_sponsor{
            padding-top: 70px;
        }

        #selc_faq{
            .ed_social{
                img{
                    width: 40px;
                }

                .ed_social_text p{
                    font-size: 16px;
                }
            }
        }
        
        #selc_stages{
            #stages{
                .stage_grid{
                    .row{
                        width: 100% !important;
                        .column:first-child{
                            padding-right: 14px;
                            margin-bottom: 5px;
                        }
                        .column{
                            width: 100% !important;
                        }

                        .column:last-child{
                            .segment{
                                flex-direction: column;
                            }
                        }
                    }
                }
            }
        } 
    }

    #selc5_rule_modal{
        .angle.icon:focus{
            color: #92D8F0 !important;
        }
    }

    #selc5_leaderboard{
        #filter{
            margin-bottom: 20px;

            .ui.big.form{
                font-size: 1rem;
            }
            .ui.grid{
                .column{
                    width: 100% !important;
                }
            }

            .dateWrapper > .field{
                width: 100%;
            }

            .clndr-calendars-segment.clndr-floating.clndr-left{
                position: absolute !important;
                top: -120%;
        
                .clndr-calendars-wrapper{
                    display: block !important;
                }
            }
        }

        #stage{
            .stage_grid{
                .ui.container{
                    width: 100% !important;
                }
    
                p{
                    font-size: small;
                }
            }
            .stage_grid .row{
                .column{
                    width: 100% !important;
                }

                .column:first-child{
                    padding-right: 1rem !important;
                }

                .column:last-child{
                    .segment{
                        display: block !important;
                    }
                }
            }
        }

        #leaderboard{
            .lbTable{
                display: none;
            }

            .lbTableMobile{
                max-height: 615px;
                overflow: auto;
                display: block;
                padding-left: 15px;
                padding-right: 15px;

                .ui.button{
                    border-radius: 5px !important;
                    background-color: #FFFFFF;
                }

                .no-data{
                    justify-content: center;
                }

                .row{
                    .ui.button{
                        font-size: 8px;
                        color: #8E279B;
                        margin-bottom: 3px;
                    }
                    background-color: #D1FCFF;
                    margin-bottom: 5px;
                    padding-bottom: 0.5rem;
                    border-radius: 5px;

                    .column:nth-child(2){
                        word-break: break-all;
                    }
                }

                .row.achieved{
                    background-color: #FED104;
                }

                .row > .column{
                    .icon{
                        color: #8E279B;
                        font-weight: bolder;
                    }
                }

                .row.details{
                    background-color: #fff;
                    .ui.button{
                        font-size: 12px;
                        color: #fff;
                    }

                    .column:first-child{
                        font-weight: 900;
                    }
                }

                .row.details.colored{
                    background-color: #E3EEEF !important;
                    margin-bottom: 0;
                }
            }
        } 

        #footerLeaderboard{
            .ui.grid{
                display: flex;
                flex-direction: column-reverse;

                .column:first-child{
                    display: flex;
                    flex-direction: column;
                    padding-right: 100px;
                    padding-left: 100px;

                    .ui.button{
                        margin-bottom: 5px;
                    }
                }
            }
            .ui.grid > .column{
                width: 100%;
                text-align: center;
            }

            p{
                font-size: smaller;
            }
        }
    }

    .memberProgress{
        h3.ui.header{
            color: #3A0BA5 !important;

            .sub.header{
                font-size: 0.85rem;
                margin-top: 2px;
            }
        }

        .content{
            .ui.grid{
                margin-top: 20px;
                margin-bottom: 20px;

                .ui.button{
                    border-radius: 5px !important;
                    background-color: #FFFFFF;
                    color: #8E279B;
                    font-size: 9px;
                }

                .row{
                    background-color: #D1FCFF;
                    margin-bottom: 5px;
                    padding-bottom: 0.5rem;
                    border-radius: 5px;
                }

                .progressTable{
                    background-color: #fff;
                    margin-bottom: 0;
                }
            }
        }
    }
}