$black: #272343;
$primary: #FFD018;

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/open-sans-v27-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('assets/fonts/open-sans-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('assets/fonts/open-sans-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('assets/fonts/open-sans-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('assets/fonts/open-sans-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('assets/fonts/open-sans-v27-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
.telegram{
    color: #40B3E0 !important;
}

body::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

input,.ui.button{
    border-radius: 50px !important;
}

textarea{
    border-radius: 20px !important;
}

body::-webkit-scrollbar{
    width: 6px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	background-color: $primary;
}

.joinBtn{
    background: linear-gradient(180deg, #37AEE2 0%, #62C3EE 100%) !important;
    color: white !important;
    border-radius: 50px !important;
    transition: .33s all ease !important;
    opacity: 0.9 !important;
}

.joinBtn:hover{
    opacity: 1 !important;
}

.ui.inverted.menu{
    font-family: 'Open Sans' !important;
    background-color: $primary !important;
    border-radius: 0;
    justify-content: center;
    box-shadow: 0 1px 2px 0 #22242626 !important;
    .item{
        border: none !important;
        font-weight: 700 !important;
        color: $black !important;
        margin-right: 30px;
        img{
            margin-right: 10px;
        }
    }

    .item:before{
        width: 0px;
    }
}

.ui.header{
    font-family: 'Open Sans' !important;
    // color: $black !important;
}

.ui.menu, p{
    font-family: 'Open Sans' !important;
}

h1.ui.header {
    font-size: 3rem !important;
}

#refreshText{
    i{
        color: #276f86;
    }
}

.sidePattern{
    background: url('./assets/images/ols_pattern.png');
}

.sidePattern_R{
    background: url('./assets/images/ols_pattern_r.png');
}

// mobile
@media only screen and (min-width:320px) and (max-width:600px){
    #ed_task_desktop{
        display: none;
    }

    .quickButtons{
        display: none;
    }

    #ed_task_mobile{
        display: flex !important;
        h2{
            text-align: center !important;
        }
    }

    h1.ui.header {
        font-size: 1.8rem !important;
    }

    #findme_desktop{
        display: none;
    }

    #findme_mobile{
        display: block !important;
    }

    .rh5v-DefaultPlayer_component {
        width: 100% !important;
    }

    #ed_social_desktop{
        display: none;
    }

    #ed_social_mobile{
        display: flex !important;

        .ed_social_text p{
            font-size: small !important;
        }
    }

    #ed_end_dektop{
        display: none;
    }

    #ed_end_mobile{
        display: block !important;
    }

    #menu_dekstop{
        display: none;
    }

    #menu_mobile{
        display: flex !important;
    }

    #ed_lb_desktop{
        display: none !important;
    }

    #ed_lb_mobile{
        display: inline-block !important;
    }

    .ui.inverted.menu .active.item{
        width: 100% !important;
    }

    .countdown{
        display: none !important;
    }

    .countdown_mobile{
        display: flex !important;
    }

    .bannerBackground{
        min-height: 45vh !important;
    }

    #banner2{
        position: absolute !important;
        bottom: 0 !important;
    }

    #banner1{
        left: 0 !important;
        top: 50px !important;
    }

    .sidePattern, .sidePattern_R{
        display: none !important;
    }

    #mainColumn{
        width: 100% !important;
    }

    #main_content{
        .ui.grid > .row:first-child{
            padding: 30px !important;
        }
    }

    #counterSegment{
        top: -40px !important;
        width: auto !important;
        left: 0 !important;
        width: 100% !important;

        h1:not(.countDownWrap>h1){
            font-size: 40px !important;
        }

        .countdownWrap{
            h1{
                font-size: 34px !important;
                margin-right: 10px !important;
            }

            span{
                font-size: 20px !important;
            }
        }
    }

    #progressPig{
        margin-top: 8em !important;

        .progress{
            font-size: 1.928571rem !important;
            .check.icon{
                font-size: 1.3em;
                margin-left: 13% !important;
                width: 36px !important;
            }

            .bar{
                .ui.big.label{
                    font-size: 2rem;
                }
            }
        }

        .ui.tiny.image{
            width: 40px;
        }
        .checkPointText{
            margin-left: 3% !important;
            font-size: 8px !important;
        }

        .endPointText{
            margin-left: 80% !important;
            font-size: 8px !important;
        }

        #pigProgressMobile{
            margin-top: -70px !important;
            left: 39px !important;
        }

        #pigProgress{
            display: none !important;
        }

        #fullPig{
            top: 25px !important;
        }
    }

    #refreshText{
        p, i{
            font-size: 12px;
        }
    }

    #popUpPeople{
        img{
            width: 30px;
        }
        .sub.header{
            font-size: 12px;
        }
    }

    #joinBtn{
        font-size: 1.714286rem !important;
    }

    #main_content{
        .ui.segment:not(#counterSegment):not(#hof){
            padding-top: 140px !important;
            padding-bottom: 50px !important;
        }

        #zombieColumn, #survivalColumn{
            width: 100%;
        }
    }

    #one_last_standing_j{
        #banner_j{
            #banner1{
                top: 15px !important;
                width: 250px;
                margin-bottom: 20px;
            }
            #banner3{
                width: 170px;
                right: 70px !important;
            }

            #banner2{
                width: 100px;
                right: 10px;
            }
        }

        #timelineWrapper{

            h2{
                font-size: 18px;
            }

            .right{
                left: 0 !important;
            }

            .t_container{
                width: 90% !important;
                margin-left: 40px;
            }
            .timelineLogo{
                left: -25px !important;
            }
            
            .t_container.right{
                .timelineLogo {
                    left: -25px !important
                }
            } 
        } 

        #timelineWrapper::after {
            left: 40px !important;
            margin-left: 0px;
        }

        #filter-type, #filter-apply{
            width: 100% !important;
        }
    }

    #contact_page{
        .ui.grid > .column{
            width: 100% !important;
        }
    }

    #ed_countdown{
        .countDownWrapper{
            display: block !important;
        }
    }

    .hof{
        .headerHof{
            justify-content: center !important;
            flex-direction: column;
        }
        .hofItem{
            justify-content: center !important;
            align-items: center !important;
            flex-direction: column;

            .testimoni{
                width: 100% !important;
                margin-left: 0 !important;
                margin-top: 20px;

                p{
                    font-size: 16px !important;
                }
            }
        }
    }

    #hof{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    #selc_page{
        #m_join{
            display: block !important;
        }

        #d_join{
            display: none;
        }

        #selc_banner_2{
            .banner_grid{
                .column:first-child{
                    display: none;
                }
                .column{
                    width: 100% !important;

                    h1{
                        text-align: center;
                    }

                    img{
                        width: 70%;
                    }
                }
            }
        }

        .stage_grid{
            .row{
                .icon{
                    display: none !important;
                }
            }
        }

        #selc_lb_btn, #selc_qs_btn{
            font-size: 14px !important;
            text-align: left;
        }

        #qs_filter_form > .fields{
            flex-direction: column;
            input{
                margin-bottom: 5px;
            }
        }

        #selc_leaderboard{
            #lb_qs_table > tbody > tr{
                display: flex !important;
                justify-content: space-between;
            }

            #lb_qs_table{
                background-color: #fff;
            }

            #lb_table{
                display: none;
            }

            #lb_table_mobile{
                display: block !important;
                margin-bottom: 10px;

                .ui.styled.accordion{
                    box-shadow: none;

                    .title{
                        color: #272343;
                        font-weight: 500;
                        padding: 15px;
                        .dropdown.icon{
                            transform: rotate(90deg);
                        }
                    }

                    .active.title .dropdown.icon {
                        transform: rotate(180+90deg);
                    }

                    .content{
                        background: rgba(151, 210, 237, 0.3213);
                    }
                }
            }
        }
    }

    #rule_modal .content .rule_grid .row .column .segment{
        font-size: 8px !important;
    }

    #rule_modal > .angle.icon{
        font-size: 30px;
    }

    #progressForm{
        .clndr-calendars-segment.clndr-floating.clndr-left{
            position: absolute !important;
            top: -400%;
    
            .clndr-calendars-wrapper{
                display: block !important;
            }
        }
    }
}

// tablet
@media only screen and (min-width:601px) and (max-width:768px){
    #ed_task_desktop{
        display: none;
    }

    .quickButtons{
        display: none;
    }

    #ed_task_mobile{
        display: flex !important;
        h2{
            text-align: center !important;
        }
    }

    h1.ui.header {
        font-size: 1.8rem !important;
    }

    #findme_desktop{
        display: none;
    }

    #findme_mobile{
        display: block !important;
    }

    .rh5v-DefaultPlayer_component {
        width: 100% !important;
    }

    #ed_social_desktop{
        display: none;
    }

    #ed_social_mobile{
        display: flex !important;

        .ed_social_text p{
            font-size: small !important;
        }
    }

    #ed_end_dektop{
        display: none;
    }

    #ed_end_mobile{
        display: block !important;
    }

    #menu_dekstop{
        display: none;
    }

    #menu_mobile{
        display: flex !important;
    }

    #ed_lb_desktop{
        display: none !important;
    }

    #ed_lb_mobile{
        display: inline-block !important;
    }

    .ui.inverted.menu .active.item{
        width: 100% !important;
    }

    .countdown{
        display: none !important;
    }

    .countdown_mobile{
        display: flex !important;
    }

    .bannerBackground{
        min-height: 45vh !important;
    }

    #banner2{
        position: absolute !important;
        bottom: 0 !important;
    }

    #banner1{
        left: 0 !important;
        top: 50px !important;
    }

    .sidePattern, .sidePattern_R{
        display: none !important;
    }

    #mainColumn{
        width: 100% !important;
    }

    #main_content{
        .ui.grid > .row:first-child{
            padding: 30px !important;
        }
    }

    #counterSegment{
        top: -40px !important;
        width: auto !important;
        left: 0 !important;
        width: 100% !important;

        h1:not(.countDownWrap>h1){
            font-size: 40px !important;
        }

        .countdownWrap{
            h1{
                font-size: 34px !important;
                margin-right: 10px !important;
            }

            span{
                font-size: 20px !important;
            }
        }
    }

    #progressPig{
        margin-top: 8em !important;

        .progress{
            font-size: 1.928571rem !important;
            .check.icon{
                font-size: 1.3em;
                margin-left: 20% !important;
                width: 36px !important;
            }

            .bar{
                .ui.big.label{
                    font-size: 2rem;
                }
            }
        }

        .ui.tiny.image{
            width: 40px;
        }
        .checkPointText{
            margin-left: 3% !important;
            font-size: 8px !important;
        }

        .endPointText{
            margin-left: 90% !important;
            font-size: 8px !important;
        }

        #pigProgressMobile{
            margin-top: -70px !important;
            left: 39px !important;
        }

        #pigProgress{
            display: none !important;
        }

        #fullPig{
            top: 25px !important;
        }
    }

    #refreshText{
        p, i{
            font-size: 12px;
        }
    }

    #popUpPeople{
        img{
            width: 30px;
        }
        .sub.header{
            font-size: 12px;
        }
    }

    #joinBtn{
        font-size: 1.714286rem !important;
    }

    #main_content{
        .ui.segment:not(#counterSegment):not(#hof){
            padding-top: 140px !important;
            padding-bottom: 50px !important;
        }

        #zombieColumn, #survivalColumn{
            width: 100%;
        }
    }

    #one_last_standing_j{
        #banner_j{
            #banner1{
                top: 15px !important;
                width: 250px;
                margin-bottom: 20px;
            }
            #banner3{
                width: 170px;
                right: 70px !important;
            }

            #banner2{
                width: 100px;
                right: 10px;
            }
        }

        #timelineWrapper{

            h2{
                font-size: 18px;
            }

            .right{
                left: 0 !important;
            }

            .t_container{
                width: 90% !important;
                margin-left: 40px;
            }
            .timelineLogo{
                left: -25px !important;
            }
            
            .t_container.right{
                .timelineLogo {
                    left: -25px !important
                }
            } 
        } 

        #timelineWrapper::after {
            left: 40px !important;
            margin-left: 0px;
        }

        #filter-type, #filter-apply{
            width: 100% !important;
        }
    }

    .hof > .hofItem > .testimoni > p{
        font-size: 16px !important;
    }

    #rule_modal > .content > .ui.text.container{
        width: 100% !important;
    }
}

// laptop / ipad air
@media only screen and (min-width:769px) and (max-width:1024px){
    #ed_task_desktop{
        display: none;
    }

    .quickButtons{
        display: none;
    }

    #ed_task_mobile{
        display: flex !important;
        h2{
            text-align: center !important;
        }
    }

    h1.ui.header {
        font-size: 1.8rem !important;
    }

    #findme_desktop{
        display: none;
    }

    #findme_mobile{
        display: block !important;
    }

    .rh5v-DefaultPlayer_component {
        width: 100% !important;
    }

    #ed_social_desktop{
        display: none;
    }

    #ed_social_mobile{
        display: flex !important;

        .ed_social_text p{
            font-size: small !important;
        }
    }

    #ed_end_dektop{
        display: none;
    }

    #ed_end_mobile{
        display: block !important;
    }

    #menu_dekstop{
        display: none;
    }

    #menu_mobile{
        display: flex !important;
    }

    #ed_lb_desktop{
        display: none !important;
    }

    #ed_lb_mobile{
        display: inline-block !important;
    }

    .ui.inverted.menu .active.item{
        width: 100% !important;
    }

    .countdown{
        display: none !important;
    }

    .countdown_mobile{
        display: flex !important;
    }

    .bannerBackground{
        min-height: 45vh !important;
    }

    #banner2{
        position: absolute !important;
        bottom: 0 !important;
    }

    #banner1{
        left: 0 !important;
        top: 50px !important;
    }

    .sidePattern, .sidePattern_R{
        display: none !important;
    }

    #mainColumn{
        width: 100% !important;
    }

    #main_content{
        .ui.grid > .row:first-child{
            padding: 30px !important;
        }
    }

    #counterSegment{
        top: -40px !important;
        width: auto !important;
        left: 0 !important;
        width: 100% !important;

        h1:not(.countDownWrap>h1){
            font-size: 40px !important;
        }

        .countdownWrap{
            h1{
                font-size: 34px !important;
                margin-right: 10px !important;
            }

            span{
                font-size: 20px !important;
            }
        }
    }

    #progressPig{
        margin-top: 8em !important;

        .progress{
            font-size: 1.928571rem !important;
            .check.icon{
                font-size: 1.3em;
                margin-left: 13% !important;
                width: 36px !important;
            }

            .bar{
                .ui.big.label{
                    font-size: 2rem;
                }
            }
        }

        .ui.tiny.image{
            width: 40px;
        }
        .checkPointText{
            margin-left: 3% !important;
            font-size: 8px !important;
        }

        .endPointText{
            margin-left: 90% !important;
            font-size: 8px !important;
        }

        #pigProgressMobile{
            margin-top: -70px !important;
            left: 39px !important;
        }

        #pigProgress{
            display: none !important;
        }

        #fullPig{
            top: 25px !important;
        }
    }

    #refreshText{
        p, i{
            font-size: 12px;
        }
    }

    #popUpPeople{
        img{
            width: 30px;
        }
        .sub.header{
            font-size: 12px;
        }
    }

    #joinBtn{
        font-size: 1.714286rem !important;
    }

    #main_content{
        .ui.segment:not(#counterSegment):not(#hof){
            padding-top: 140px !important;
            padding-bottom: 50px !important;
        }

        #zombieColumn, #survivalColumn{
            width: 100%;
        }
    }

    #one_last_standing_j{
        #banner_j{
            #banner1{
                top: 15px !important;
                width: 250px;
                margin-bottom: 20px;
            }
            #banner3{
                width: 170px;
                right: 70px !important;
            }

            #banner2{
                width: 100px;
                right: 10px;
            }
        }

        #timelineWrapper{

            h2{
                font-size: 18px;
            }

            .right{
                left: 0 !important;
            }

            .t_container{
                width: 90% !important;
                margin-left: 40px;
            }
            .timelineLogo{
                left: -25px !important;
            }
            
            .t_container.right{
                .timelineLogo {
                    left: -25px !important
                }
            } 
        } 

        #timelineWrapper::after {
            left: 40px !important;
            margin-left: 0px;
        }

        #filter-type, #filter-apply{
            width: 100% !important;
        }
    }

    .hof > .hofItem > .testimoni > p{
        font-size: 16px !important;
    }

    #rule_modal > .content > .ui.text.container{
        width: 100% !important;
    }
}

// larger screen
@media only screen and (min-width:1025px) and (max-width:1920px){
    #pigProgressMobile{
        display: none !important;
    }
}

// HOME PAGE
#home_page, #challenge_page, #contact_page, #event_page, #selc_page{
    min-height: 100vh;
    margin-top: 100px;
}

#ed_academy{
    .ui.segment{
        background: url('./assets/images/Rectangle.svg') no-repeat;
        background-position: bottom;
        background-size: 100%;
    }
}

h2{
    font-weight: 400 !important;
}

#ed_task{
    h2{
        line-height: 1.5;
        margin-top: 30px;
    }
}

#ed_findme{
    h3{
        font-weight: 400;
    }
}

#ed_findme_grid{
    img{
        opacity: 1;
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }
    a:hover{
        img{
            opacity: .5 !important;
        }
    }
}

#ed_video{
    .segment{
        div{
            display: flex;
            justify-content: center;
        }
    }
}
.rh5v-DefaultPlayer_component {
    width: 50%;
    border-radius: 20px;
}
.rh5v-DefaultPlayer_video{
    border-radius: 20px;
}

#ed_socialmedia{
    .ed_social{
        display: flex;
        justify-content: center;
        align-items: center;

        .ed_social_text p{
            margin-left: 10px;
            font-size: x-large;
            line-height: 0.5;
            text-align: left;
            cursor: pointer;
            opacity: 1;
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
        }

        .ed_social_text p:hover{
            opacity: .5 !important;
        }
    }
}

.footer1{
    padding: 0 !important;
    background: #2D2D2D !important;
    .ui.menu{
        justify-content: center;
    }
    .item{
        margin-top: 15px !important;
        color: #fff !important;
    }
}

.footer2{
    .copyright{
        color: #fff !important;
    }
}

.menu_m{
    .ui.sidebar{
        i{
            position: absolute;
            right: 25px;
            margin: 29px;
            z-index: 989;
        }
    }
}

#menu_mobile{
    img{
        margin-left: 20px;
    }
}

.pushable:not(body) {
    transform: none !important;
}

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
    position: fixed !important;
}

.ed_loading:after{
    border-color: $primary transparent transparent !important;
}

.ed_loading p{
    margin-top: 60px !important;
    text-align: center;
}

.breath{
    animation-name: breathe;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-delay: 0;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes breathe {
    0% {
      font-size: large;
    }
    
    50% {
      font-size: x-large;
    }
    
    100% {
      font-size: large;
    }
}

.ui.massive.loader:after, .ui.massive.loader:before{
    width: 8.571429rem !important;
    height: 8.571429rem !important;
    margin: 0 0 0 -4.285714rem !important;
}

#ed_countdown, #ed_leaderboard{
    .countDownWrapper{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 70px;
        h1{
            margin: 0 60px 0 0;
        }
    }
    .ui.segment{
        background-color: $primary !important;
        border-radius: 10px;
        padding: 20px;
    }

    .countdown{
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        
        h1 > span{
            margin-left: 3px;
            color: #fff;
            font-size: 3rem;
        }

        h1{
            margin-right: 30px;
            font-size: 4rem !important;
        }
    }

    .countdown_mobile{
        justify-content: flex-start;
        align-items: baseline;
        
        h1 > span{
            margin-left: 3px;
            color: #fff;
            font-size: 1.5rem;
        }

        h1{
            margin-right: 10px;
            font-size: 2.5rem !important;
        }
    }
}

#ed_leaderboard{
    .ed_lb_table{
        tbody{
            tr{
                td{
                    .plain{
                        font-weight: 500 !important;
                    }
                }
            }
        }
    }

    #see_all{
        background-color: white !important;
    }
}

.ed_leaderboard{
    display: flex !important;
    align-items: flex-start;
    .ui.dropdown{
        margin: 4px 0px 0px 40px;
    }
}


#contact_page{
    .icon:not(.telegram){
        color: $primary !important;
    }

    #contact_form{
        .ui.button{
            background-color: $primary !important;
            color: black !important;
            opacity: 1;
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;

            .icon{
                color: black !important;
            }
        }

        .ui.button:hover{
            opacity: .5 !important;
        }
    }
}

.ui.inverted.menu .active.item{
    background-color: black !important;
    // border-bottom: 5px solid #000 !important;
}

.ui.vertical.menu .item>.menu{
    margin-left: 10px !important;
}

.go_top{
    position:fixed;
    width:40px;
    height:40px;
    bottom:30px;
    right:10px;
    background-color:#FED104 !important;
    color:#000 !important;
    border-radius:50px;
    text-align:center;
    box-shadow: 2px 2px 3px #fff;
    border-radius: 50px !important;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

#privacy_policy, #toc{
    p, ul>li{
        font-size: large;
    }

    dl > dt{
        font-size: large;
        font-weight: 600 !important;
    }

    dl > dd{
        font-size: medium;
    }

    h2{
        font-size: x-large;
        font-weight: 700 !important;
    }

    ol > li{
        font-size: large;
    }
}

#faq{
    .title{
        font-size: large;
    }

    .content > ul > li{
        font-size: large;
    }
}

#one_last_standing{
    min-height: 200vh;
    margin-top: 65px;

    .quickButtons{
        position: fixed;
        left: 20px;
        top: 150px;
        z-index: 101;
        // cursor: pointer;

        .ui.menu{
            background-color: #FFD018;
            border-radius: 50px;
            padding-top: 30px;
            padding-bottom: 30px;
            width: 100%;
            transition: height 0.5s;
            -webkit-transition: height 0.5s;
            text-align: center;
            overflow: hidden;
            transition: 0.5s;

            .item::before{
                background: none !important;
            }

            .item{
                margin-bottom: 10px;
            }

            i{
                font-size: 18px;
            }

            span{
                display: none;
            }
        }

        .ui.menu:hover{
            width: 15vw;
            .item{
                margin-bottom: 20px;
            }
            i{
                float: left;
                text-align: left;
            }

            span{
                display: inline;
            }
        }
    }

    

    #banner{
        .bannerBackground{
            background: url('./assets/images/ols_banner3.png');
            background-repeat: no-repeat;
            background-size: cover;
            min-height: 100px;
            #banner1{
                left: 250px;
                top: 100px;
            }
        }
    }

    #main_content{
        position: relative;
        #counterSegment{
            position: absolute;
            background-color: $primary;
            top: -10px;
            width: 100%;
        }

        #progressPig{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-top: 20em;
            // margin-bottom: 15em;

            .progress.passed{
                background: #B2ECFF !important;
            }
            .progress{
                border-radius: 50px !important;
                display: flex;
                align-items: center;
                width: 100%;
                margin-right: 5px;
                margin-left: 5px;

                .bar{
                    align-items: center;
                    display: flex;
                    border-radius: 50px !important;
                    justify-content: right;
                    background: #23307F !important;
                }

                .checkPointText{
                    margin-left: 15%;
                    position: absolute;
                    font-size: 12px;
                    color: #b7b7b7;
                    z-index: 2;
                }
                .endPointText{
                    margin-left: 90%;
                    position: absolute;
                    font-size: 12px;
                    color: #b7b7b7;
                    z-index: 2;
                }

                .check.icon{
                    margin-left: 22%;
                    position: absolute;
                    z-index: 1;
                    background: #fff;
                    border-radius: 50px;
                }
            }

            #pigProgress{
                margin-top: -100px;
                position: absolute;
                // left: 0;
            }

            #fullPig{
                top: -10px;
            }
        }

        #joinBtn{
            font-size: 2.714286rem;
        }

        .ui.segment:not(#counterSegment){
            padding-top: 70px;
            padding-bottom: 70px;
        }

        .hof{
            background-color: #FFD803;
            .headerHof{
                display: flex;
                justify-content: center;
                align-items: flex-start;

                h1{
                    margin-right: 70px;
                }
            }
            .hofItem{
                margin-top: 70px;
                display: flex;
                justify-content: center;
                align-items: flex-start;

                .testimoni{
                    background-color: #fff;
                    border-radius: 20px;
                    width: 50%;
                    margin-left: 20px;
                    padding: 20px;

                    p{
                        font-weight: bold !important;
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .olsTable{
        background: $primary;
        padding: 20 !important;
        table{
            font-size: 24px;
        }
    }

    .countdownWrap{
        display: flex;
        justify-content: center;
        align-items: baseline;
        
        h1 > span{
            margin-left: 3px;
            color: #fff;
            font-size: 3rem;
        }

        h1{
            margin-right: 30px;
        }
    }
}
#PopUpMaster{
    z-index: 1;
}

#popUpPeople{
    display: flex;
    align-items: center;
    p{
        margin-left: 10px;
        margin-top: 0px;
    }
}

#not-found{
    min-height: 84vh;
    padding-top: 70px;
    padding-bottom: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#one_last_standing_j{
    min-height: 200vh;
    margin-top: 65px;
    #banner_j{
        .bannerBackground_j{
            background: url('./assets/images/ols_banner3.png');
            background-repeat: no-repeat;
            background-size: cover;
            min-height: 100px;
            #banner1{
                top: 100px;
            }
        }

        .bottomImg{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    #main_column_j{
        padding-top: 70px;
        .ui.input{
            width: 100%;
        }
    }

    #filter-type{
        margin-right: 6px;
        font-size: 17px;
        border-radius: 50px;

        .item{
            font-size: 17px;
        }
    }

    #timelineWrapper {
        position: relative;
        max-width: 1200px;
        margin: 0 auto;

        .t_container {
            padding: 0px 40px;
            position: relative;
            background-color: inherit;
            width: 50%;
        }

        .timelineLogo{
            position: absolute;
            width: 50px;
            height: 50px;
            right: -25px;
            top: 0px;
            border-radius: 50%;
            z-index: 1;
        }

        .t_container.right{
            .timelineLogo{
                left: -25px;
            }
        }

        .left {
            left: 0;
        }

        .right {
            left: 50%;
        }

        .right::after {
            left: -13px;
        }

        .t_content {
            padding: 20px 30px;
            background-color: white;
            position: relative;
            border-radius: 6px;
        }

        .gain_extra_live_title{
            background-color: #9F0242;
        }

        .gain_extra_live_content{
            background-color: #C50351;
        }

        .resurrect_title{
            background-color: #68853C;
        }

        .resurrect_content{
            background-color: #A9DE59;
            table > tbody > tr > td{color: #000 !important}
        }

        .survive_title{
            background-color: #1A5299;
        }

        .survive_content{
            background-color: #2871CC;
        }

        .deceased_title{
            background-color: #343434;
        }

        .deceased_content{
            background-color: #5B5B5B;
        }

        .join_title{
            background-color: #C8A200;
        }

        .join_content{
            background-color: #F9CA03;
            table > tbody > tr > td{color: #000 !important}
        }

        table{
            border: none !important;
            tr > td{
                border: none;
            }
        }

        table > tbody > tr > td{
            color: #fff;
        }

        h2{
            color: #fff !important;
        }
    }

    #timelineWrapper::after {
        content: '';
        position: absolute;
        width: 6px;
        background-color: #FFD803;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -3px;
    }
}

#event_page{
    section{
        margin-bottom: 100px;
    }
}

#selc_page{

    .quickButtons{
        position: fixed;
        left: 20px;
        top: 150px;
        z-index: 101;
        // cursor: pointer;

        .ui.menu{
            background-color: #3A0BA5;
            border-radius: 50px;
            padding-top: 30px;
            padding-bottom: 30px;
            width: 100%;
            transition: height 0.5s;
            -webkit-transition: height 0.5s;
            text-align: center;
            overflow: hidden;
            transition: 0.5s;

            .item::before{
                background: none !important;
            }

            .item{
                margin-bottom: 10px;
                color: #FFF !important;
            }

            i{
                font-size: 18px;
                text-align: center;
            }

            span{
                display: none;
            }
        }

        .ui.menu:hover{
            width: 15vw;
            .item{
                margin-bottom: 20px;
            }
            i{
                float: left;
                text-align: center;
            }

            span{
                display: inline;
            }
        }
    }

    #m_join{
        display: none;
    }

    h1, h2{
        font-family: 'Poppins' !important;
        font-weight: bolder !important;
    }

    #selc_banner{
        padding-bottom: 20px;
    }

    #selc_banner_2{
        .banner{
            background-color: #FED104;
            padding-bottom: 20px;
            padding-top: 40px;

            .banner_grid{
                h1{
                    color: #fff !important;
                }
            }
        }
    }

    #selc_info{

        .ui.list{
            a{
                text-decoration: underline;
                color: #000;
            }
        }

        h2{
            color: #3A0BA5 !important;
        }
        .info{
            padding-bottom: 70px;
            padding-top: 70px;
            background-color: #D1FCFF;
        }
    }

    #selc_comp{
        padding-bottom: 70px;
        padding-top: 70px;

        h2{
            color: #3A0BA5 !important;
        }

        .comp_header{
            color: #7C768A !important;
        }

        .selc_btn_group_div{
            margin-top: 70px;
            margin-bottom: 70px;
        }

        .ui.button{
            padding: 0.785714em 4.5em .78571429em !important;
        }

        #stages{
            padding-bottom: 70px;
            
            .stage_grid{
                h2{
                    font-size: 16px;
                    margin: 0;
                }
                
                .row{
                    padding-bottom: 0;
                    .column:first-child{
                        padding-right: 0;

                        .ui.grid{
                            .column:last-child{
                                display: flex;
                                align-items: center;
                            }
                        }
                    }

                    .column:last-child > .segment > .ui.grid > .column{
                        display: flex;
                        align-items: center;
                    }

                    .segment{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
                .row:not(.active) > .column > .segment{
                    h2, i{
                        color: #7C768A !important;
                        font-weight: normal !important;
                    }
                    background-color: #E3EEEF;
                }

                .row.active{
                    h2{
                        color: #fff !important;
                    }
                    .column > .segment{
                        background-color: #8E279B;
                        color: #fff;
                    }

                    .stage_status > span{
                        margin: 1px;
                    }
                }
            }
        }

        #selc_video_winner{
            margin-top: 70px;
        }
    }

    #selc_leaderboard{
        .lb_wrapper{
            padding-bottom: 70px;
            padding-top: 70px;
            background-color: #FFD803;

            .btn_lb_wrapper{
                display: flex;
                justify-content: center;
                // text-align: center;

                #selc_lb_btn, #selc_qs_btn{
                    border: 1px solid #F99500 !important;
                    background-color: transparent;
                    transition: .33s all ease !important;
                    opacity: 0.9;
                    box-shadow: none !important;
                }

                #selc_lb_btn:hover,#selc_qs_btn:hover{
                    opacity: 1;
                    background-color: #f99500c7;
                    color: #FFF;
                }

                #selc_lb_btn{
                    border-radius: 50px 0px 0px 50px !important;
                }

                #selc_lb_btn.active,#selc_qs_btn.active{
                    background-color: #F99500 !important;
                    color: #FFF;
                }
    
                #selc_qs_btn{
                    border-radius: 0px 50px 50px 0px !important;
                }
            }

            #lb_table_mobile{
                display: none;
            }

            #lb_table tr td{
                border-top: 1px solid rgba(255, 255, 255, 0.632);
                button{
                    background: none;
                    text-decoration: underline;
                    color: black;
                }
            }

            #lb_qs_table tr td{
                border-top: 1px solid rgba(255, 255, 255, 0.632);
            }

            #lb_table thead th{
                border-bottom: 0;
            }

            #qs_filter_form, #qs_filter_form_all{
                .fields{
                    justify-content: center;
                }

                input{
                    border: 0 !important;
                }
            }
        }
    }

    #sponsored_by{
        padding-top: 70px;
    }

    #selc_faq{
        padding-bottom: 70px;
        padding-top: 70px;

        #faq_content{
            .title{
                background-color: #E3EEEF;
            }
            .title > h1{
                color: #7C768A;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .content{
                padding-top: 20px;
            }

            h3{
                color: #7C768A;

                .sub.header{
                    margin-top: 5px;
                    line-height: 21px;
                    color: #797979;
                    b{
                        color: #585858;
                    }
                }
            }
        }
    }
}

#rule_modal{
    .content{
        padding: 50px !important;

        h1{
            color: #3A0BA5;
            font-family: 'Poppins' !important;
            font-weight: bolder !important;
        }

        .rule_grid{
            .row{
                padding-top: 0 !important;
                padding-bottom: 0.5rem !important;
                .column{
                    padding-left: 0rem !important;
                    padding-right: 0.5rem !important;

                    .segment{
                        background-color: #E3EEEF;
                        color: #7C768A;
                        border: 0;
                    }
                }

                .column:not(:first-child){
                    .segment{
                        text-align: center;
                    }
                }
            }

            .row:first-child{
                .column > .segment{
                    background-color: #8E279B !important;
                    color: #fff;
                }
            }
        }
    }

    .angle.icon:hover{
        color: #23307F;
    }

    .angle.icon{
        color: #92D8F0;
        position: absolute;
        top: 50%;
    }

    .angle.left.icon{
        left: 0;
    }

    .angle.right.icon{
        right: 0;
    }
}

#all_lb_table > tbody > tr > td{
    button{
        background: none;
        text-decoration: underline;
        color: black;
    }
}

.detail_lb_table tbody tr td:first-child{
    word-break: break-all;
}


// external

#joinLoader{
    h1{
        color: #FFD803 !important;
    }
    .ui.loader::before{
        border-color: #FFD803 !important;
    }

    .ui.loader{
        p{
            margin-top: 80px;
        }
    }
}

#link_telegram{
    text-align: center;
    font-weight: 900;
    font-size: 14px;
    a {
        text-decoration: underline;
        font-weight: 400;
        color: inherit;
    }
}