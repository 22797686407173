#register_page{
    .ui.disabled.button{
        cursor: not-allowed;
        pointer-events: visible;
    }
    min-height: 100vh;
    margin-top: 66px;

    #register_banner{
        padding-bottom: 20px;
    }

    #register_banner_2{
        .banner{
            background-color: #FED104;
            padding-bottom: 20px;
            padding-top: 40px;

            h1{
                color: #fff !important;
            }
        }
    }

    .ui.button{
        box-shadow: 2px 2px 10px rgba(13, 71, 161, 0.25);
        border-radius: 100px;
        opacity: 0.9;
        transition: 0.5s;
    }

    .ui.primary.button:not(.ui.disabled.button){
        background: linear-gradient(90deg, #1976D2 0%, #00BCD4 100%);
    }

    .ui.secondary.button:not(.ui.disabled.button){
        background: linear-gradient(90deg, #1976D2 0%, #00BCD4 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        span{
            background: linear-gradient(90deg, #1976D2 0%, #00BCD4 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }

    .ui.button:hover{
        opacity: 1;
    }

    input,textarea,.ui.dropdown{
        background: #FFFFFF;
        border: 1px solid rgba(33, 150, 243, 0.25);
        box-shadow: inset 1px 1px 5px rgba(33, 150, 243, 0.25);
        border-radius: 10px !important;
    }

    .ui.dropdown{
        .menu{
            border-radius: 0px 0px 10px 10px;
        }
    }

    input:focus,textarea:focus,.ui.dropdown:focus{
        border: 1px solid rgba(33, 150, 243, 0.62);
    }

    .teamList{
        max-height: 350px;
        min-height: 100px;
        overflow: auto;
        .row{
            background-color: #D1FCFF;
            border-radius: 10px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }

        .row.full{
            background-color: #E5E5E5;
            .ui.label{
                width: 100%;
                border-radius: 10px;
                background: grey;
                color: #fff;
            }

            h3{
                color: rgba(3, 28, 48, 0.5);
            }
        }
    }

    #teamLoadingWrapper{
        min-height: 150px;
    }

    .successStep{
        .edPLayLink{
            margin-top: 150px;
            a:hover{
                text-decoration: underline;
            }
        }

        p{
            i{font-size: 20px; margin: 5px;}
            display: flex;
            justify-content: center;
            align-items: center;
        }

        h3{
            color: #23307F;
            font-weight: bolder !important;
            b{
                font-weight: bolder !important;
            }
        }
    }

    h1, h2{
        color: #23307F;
    }
}

.modalRegister{
    .ui.button{
        box-shadow: 2px 2px 10px rgba(13, 71, 161, 0.25) !important;
        border-radius: 100px;
        opacity: 0.9;
        transition: 0.5s;
        padding-left: 50px;
        padding-right: 50px;
    }

    .ui.primary.button{
        background: linear-gradient(90deg, #1976D2 0%, #00BCD4 100%);
    }

    .ui.secondary.button:not(.ui.disabled.button){
        background: linear-gradient(90deg, #1976D2 0%, #00BCD4 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        span{
            background: linear-gradient(90deg, #1976D2 0%, #00BCD4 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }

    .ui.button:hover{
        opacity: 1;
    }
}

@media only screen and (min-width:320px) and (max-width:600px){
    #register_banner_2{
        .banner_grid{
            .column:first-child{
                display: none;
            }
            .column{
                width: 100% !important;

                h1{
                    text-align: center;
                }

                img{
                    width: 70%;
                }
            }
        }
    }

    #register_page{
        .ui.container{
            padding: 0px 20px 20px 20px;
        }

        #stepOne,#stepTwo,#stepThree{
            .column{
                width: 100% !important;
            }
        }

        #stepTwoTeam{
            .ten.wide.column{
                width: 100% !important;
            }

            .teamList .row{
                .column:first-child{
                    width: 30% !important;
                }

                .column:last-child{
                    width: 70% !important;
                }
            }
        }
    }

    .modalRegister{
        .ui.primary.button{
            margin-top: 10px;
        }
    }
}